<template>
  <div class="font-s-14 app-wrapper">
    <div style="display: flex;height: 3rem;">
      <div style="height: 3rem; line-height: 3rem;padding-left: 0.5rem;padding-top: 0.1rem;  background-color: white;">
        <van-icon size="1.4rem" name="arrow-left" @click="goBack" />
      </div>
      <van-search v-model="value" placeholder="搜索商品" style="width: 100%;" shape="round" background="#FFF"
        @focus="onSearch" @search="seach">
      </van-search>
    </div>
    <div v-if="boo2">
      <tab :title="category" ref="firstTab" @scrollToAll="scrollToAll" @clossSecondPop="clossSecondPop"></tab>
      <div>
        <SecondTab v-if="tempList.length > 0" style="margin-top: 1rem;" :title="tempList" ref="secondTab"
          @scrollTo="scrollTo">
        </SecondTab>
        <loading v-if="loading"></loading>
        <div v-else>
          <div class="top-type">
            <div @click="setNomal" class="top-left" :style="{ color: form.priceType ? '#333' : '#F00' }"> 默认推荐</div>
            <div class="line"></div>
            <div @click="setPrictType" class="top-right"> 价格 <van-image style="margin-left: 0.2rem;" width="0.6rem"
                height="0.8rem" :src="form.priceType ? form.priceType == '1' ? priceup : pricedown : priceno" />
            </div>
          </div>
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <div v-if="list.length > 0">
              <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
                <goods-list-com :DataList="list"> </goods-list-com>
              </van-list>
            </div>
            <div class="empt" v-else>
            </div>
          </van-pull-refresh>
        </div>
      </div>

    </div>
    <seachview v-else ref="seach" :value="value" @value="seachvalue" :type="0" :id="id" :hotType="type"></seachview>
  </div>
</template>

<script>
import { Toast } from "vant";
import HeadChild from "../../components/HeadChild";
import NaigateTool from '../../components/index/NavigateTool';
import Tab from "../../components/goodlist/Tab.vue";
import SecondTab from "../../components/goodlist/SecondTab.vue";
import Seachview from "../../components/index/Seachview.vue";
import GoodsListCom from "../../components/GoodsListCom.vue";
let initViewfinish = false

export default {
  name: "LableClassList",
  props: {},
  data() {
    return {
      priceup: require('@/assets/images/price-up.png'),
      pricedown: require('@/assets/images/price-down.png'),
      priceno: require('@/assets/images/price-no.png'),
      col: 2,
      page: 1,
      boo2: true,
      customTitleStyle: {
        color: 'red',
        fontSize: '18px',
        fontWeight: 'bold',
        // 更多样式属性
      },

      // //列宽-有指定列数则此属性失效
      // colWidth: (pc ? window.innerHeight : window.innerWidth) / 2,
      // // 列数和列宽模式切换
      // mode: 0,
      list: [],
      currentPage: 0, // 当前页码
      pageSize: 10, // 每页个数
      totalSize: 10, // 总个数
      loading2: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      checkList: [], // 列表数据
      tabindexL: 1,
      loading: false,
      category: [],
      tempList: [],
      value: null,
      // 刷新
      isLoading: false,
      form: {
        cardId: '',
        cityCode: '',
        upgradeFlag: '',
        bathId: '',
        hotType: '',
        id: '',
        priceType: null,//null 默认推荐  1 升序  0 降序  
      },
      cakaList: [],
      titles: [],
      active: 0,
      active2: 0,
      searchValue: '',
      entorcode: '',
      isFirstHttp: true,
      upgradeFlag: '',
      bathId: '',
      id: '',
      type: '',
    };
  },
  components: {
    HeadChild,
    Tab,
    SecondTab,
    NaigateTool,
    Seachview,
    GoodsListCom
  },
  methods: {
    setPrictType() {
      if (this.form.priceType) {
        if (this.form.priceType == '1') {
          this.form.priceType = '0'
        } else {
          this.form.priceType = '1'
        }
      } else {
        this.form.priceType = '1'
      }
      this.onRefresh()
    },
    setNomal() {
      this.form.priceType = null
      this.onRefresh()
    },

    // 返回
    goBack() {
      if (this.boo2) {
        this.$router.back();
      } else
        this.boo2 = true;
    },
    onSearch() {
      // this.$refs.seach.seach(this.value);
      // this.boo2 = false;
      this.$router.push({
        path: "/SearchGoods",
        query: {
          showType: 'noLocation',
          id: this.id,
          hotType: this.type,
        }
      });
    },
    //子组件分发的事件  点击历史记录重新给输入框赋值
    seachvalue(val) {
      this.value = val;
    },
    clossSecondPop() {
      if (this.$refs.secondTab) {
        this.$refs.secondTab.clossPop()
      }
      if (this.$refs.thirdTab) {
        this.$refs.thirdTab.clossPop()
      }
    },
    nextPage() { // 加载下一页
      if (this.list.length >= 10) {
        this.loading = false
        this.form.current += 1
        this.getData()
      }
    },
    getTabIndex(index) {
      this.tabindexL = index
    },
    // 刷新
    onRefresh() {
      setTimeout(() => {
        this.form.current = 1
        this.getData();
        this.isLoading = false;
      }, 200);
    },
    getData() {
      this.form.bathId = this.bathId
      this.form.upgradeFlag = this.upgradeFlag
      this.form.id = this.id
      this.form.hotType = this.type
      this.$api
        .queryProductInfoHomeNew(this.form)
        .then((res) => {

          if (res.code == 1) {
            Toast(res.msg)
            return
          }
          if (this.form.current == 1) {
            this.list = res.data.records
          } else {
            this.list.push(...res.data.records)
          }

          this.loading = false;
          this.loading2 = false;

          if (this.form.current >= res.data.pages) {
            this.finished = true
          }
        })
        .catch((err) => {
          this.loading = false;
          this.loading2 = false;
        });
    },
    // 分类一级名字
    getTitle1(secondIndex) {
      this.$api
        .classifyNewImage(this.bathId, this.upgradeFlag, this.id, this.type, this.form.cityCode)
        .then((res) => {
          if (res.code === 0) {
            this.category = res.data
            this.$nextTick(() => {
              if (this.category[this.active].jumpPageFlag === null || this.category[this.active].jumpPageFlag === undefined) {
                this.$refs.firstTab.clickCard(this.active, true)
              } else {
                this.$refs.firstTab.clickCard(-1, true)
                this.tempList.length = 0
                this.tempList.push({ id: '', name: '全部' })
              }
            })
          } else {
            if (res.code == 1) {
              this.$Toast(res.msg);
              return
            }
          }
        })
        .catch((err) => {
        });

    },
    // 分类页面数据
    onClick(val, index) {
      this.form.category1Id = val
      this.$api
        .secondClassifyNewImage(val, this.bathId, this.upgradeFlag, this.id, this.type, this.form.cityCode)
        .then((res) => {
          if (res.code === 0) {
            this.tempList.length = 0
            this.tempList.push({ id: '', name: '全部' })
            this.tempList.push(...res.data);
            this.form.category2Id = this.tempList[index].id
            this.$nextTick(() => {
              this.$refs.secondTab.clickCard(this.active2)
            })

          } else {

            this.$Toast(res.msg);

          }
        })
        .catch((err) => {
        });
    },
    scrollToAll(index) {
      this.active = index
      this.finished = false
      if (!this.isFirstHttp) {
        this.active2 = 0
      } else
        this.isFirstHttp = false
      this.list.length = 0
      this.onClick(this.category[index].id, 0);
      // }
    },
    scrollTo(index) {
      this.active2 = index
      this.form.current = 1
      this.finished = false
      this.form.category2Id = this.tempList[index].id
      this.getData();
    },
    goDetail(val) {
      //  传递参数
      this.$router.push({
        path: "/Details",
        query: {
          id: val.id,
          brandId: val.brandId,
          specId: val.specId,
          category1Id: this.form.category1Id,
          category2Id: this.form.category2Id,
        },
      });
    },
    // 位置选择
    Switch() {
      this.$router.push("SelectAdderss");
    },
    // 调用子组件的方法
    seach() {
      this.$refs.seach.seach(this.value);
    },
    // 返回

    //子组件分发的事件  点击历史记录重新给输入框赋值
    seachvalue(val) {
      this.value = val;
    },
    repaints() {
      this.$refs.waterfall.repaints(0, 0.4);
    },
    initView() {
      this.type = this.$route.query.type
      this.id = this.$route.query.activityId
      this.active = 0
      this.active2 = 0
      this.entorcode = localStorage.getItem('entrCode')
      this.upgradeFlag = localStorage.getItem("upgradeFlag");
      this.bathId = localStorage.getItem("bathId");
      this.list = []
      // // 是否有手动选择地址
      this.form.cardId = localStorage.getItem('cardId')
      this.form.cityCode = localStorage.getItem("cityCode");
      this.getTitle1(this.active2)
    }
  },
  mounted() {
    // this.getData();
    // this.type = this.$route.query.type
    // this.id = this.$route.query.activityId
    // this.entorcode = localStorage.getItem('entrCode')
    // this.upgradeFlag = localStorage.getItem("upgradeFlag");
    // this.bathId = localStorage.getItem("bathId");

    // this.getTitle1(this.active2)
    // // // 是否有手动选择地址
    // this.form.cardId = localStorage.getItem('cardId')
    // this.form.cityCode = localStorage.getItem("cityCode");
  },
  computed: {

  },
  inject: ["reload"],
  beforeRouteEnter(to, from, next) {
    to.meta.keepAlive = true
    if (from.path !== "/Details") {
      next((vm) => {
        // vm.reload();
        vm.$nextTick(() => {
          vm.initView()
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      initViewfinish = true
      next();
    }
  },
  created() {
    if (initViewfinish) {
      initViewfinish = false
      this.initView()
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
    }
  },
  watch: {
    '$route': function () {
      if (this.active > 0) {
        this.$refs.firstTab.indexTo(this.active, false)
      }
      if (this.active2 > 0) {
        this.isFirstHttp = true
        this.$refs.secondTab.indexTo(this.active2)

      }
    },
  },
  activated() {
    const scrollTop = this.$route.meta.scrollTop;
    const $wrapper = document.querySelector('.app-wrapper');
    if (scrollTop && $wrapper) {
      $wrapper.scrollTop = scrollTop;
    }
  },
};
</script>

<style lang='scss' scoped>
.top-type {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  height: 3rem;
  align-items: center;
  margin: 0.5rem 0;

  .top-left {
    flex: 1;
    text-align: center;
  }

  .line {
    background-color: #DDD;
    height: 1.5rem;
    width: 1px;
  }

  .top-right {
    flex: 1;
    text-align: center;
  }
}

.container-water-fall {

  /* // padding: 0 28px; */
  padding: 0 3%;
  width: 100vw;
  box-sizing: border-box;
  /* background: #fafafa !important; */
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 5% 0;
}

.cell-item img {
  width: 100%;
  height: auto;
  display: block;
}




.tech-list-ll .tab-bottom {
  padding: 10px 0 !important;
  height: 0 !important;
  background: #fafafa !important;
}


.empt {
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #000;
}

.empty {
  transform: translateY(50%);
}


.img {
  height: 2rem;
  width: 2rem;
  border-radius: 0.3rem;
  vertical-align: middle;
}

.select-tag {
  background-color: #D23B2E;
  border-radius: 1rem;
  padding: 0.1rem 0.5rem;
  color: white;
}

.unselect-tag {
  background-color: white;
  color: #666;
}



/*瀑布流对应布局结束*/
</style>